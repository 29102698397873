<template lang='pug'>
div
    v-row
        v-col
            v-text-field(color='brand', search, label='Search', outlined, light, hide-details, width='200px', v-model='filters.searchString')
        v-spacer
        v-col.d-flex
            span.grey--text.pr-2.pt-5 Filters:
            v-checkbox.mr-2(label='Viewed', color='brand', light, v-model='filters.viewed')
            v-checkbox(label='Unviewed', color='brand', light, v-model='filters.unviewed')
    v-row
        v-col
            .trainingItems
                .trainingItem(v-for='item in filteredItems', :key='item.id', @click='viewItem(item)')
                    .content
                        .title.black--text {{ item.name }}
                        .viewed(v-if='isViewed(item.id)')
                            v-icon(color='green') check_circle
                            span.grey--text Viewed
                    .image(:style='{backgroundColor: getCategoryColor(item.trainingCategoryId)}')
                        v-icon(color='white', size='64') {{ item.icon }}
        v-dialog(v-model='showDialog', max-width='1600', v-if='showDialog')
            v-card(light)
                v-card-title {{ selectedItem.name }} ({{ filteredItems.indexOf(this.selectedItem) + 1 }}/{{filteredItems.length}})
                v-card-text
                    v-row
                        v-col.flex-shrink-1.flex-grow-0
                            v-btn(text, color='brand', @click='goToPreviousTraining')
                                v-icon arrow_left
                                | Previous
                        v-spacer
                        v-col.flex-shrink-1.flex-grow-0
                            v-btn(text, color='brand', @click='goToNextTraining') Next
                                v-icon arrow_right

                    v-row
                        v-col.text-center
                            template(v-if='selectedItem.videoId')
                                youtube(:video-id='selectedItem.videoId', :host='youtubeHost', player-width='75%', player-height='590px', :player-vars='{ start: 0, autoplay: 1 }', @ended='videoEnded', ref='player')
                            template(v-else)
                                .text-h4 No video available
                                v-icon(size='64') construction
                    v-row
                        v-col
                            .text-h5 Additional Resources
                    v-row(v-if='selectedItem.pdfTitle')
                        v-col
                            span Help Guide: 
                            a.blue--text(:href='getPdfUrl(selectedItem.pdfFile)', target='_blank') {{ selectedItem.pdfTitle }}
                    v-row(v-if='selectedItem.docsLink')
                        v-col
                            span Online Documentation: 
                            a.blue--text(:href='getDocsLink(selectedItem.docsLink)', target='_blank') {{ getDocsLink(selectedItem.docsLink) }}
                            
                v-card-actions
                    v-spacer
                    v-btn(text, @click='showDialog = false') Close
</template>

<script>

export default {
    components: {
    },
    props: {
        categoryId: {
            type: Number,
            default () {
                return 0;
            }
        },
    },
    data () {
        return {
            fetchingResources: false,
            items: [],
            categories: [],
            showDialog: false,
            selectedItem: {},
            youtubeHost: 'https://www.youtube-nocookie.com',
            completionRecords: [],
            filters: {
                viewed: true,
                unviewed: true,
                searchString: ''
            }
        };
    },
    computed: {
        currentUser () {
            return this.$store.state.fullUser;
        },
        filteredItems () {
            let items = [];
            for (const item of this.items) {
                const viewed = this.isViewed(item.id);
                if (this.filters.viewed && viewed) {
                    items.push(item);
                }
                if (this.filters.unviewed && !viewed) {
                    items.push(item);
                }
            }
            if (this.filters.searchString) {
                const searchString = this.filters.searchString.toLowerCase();
                items = items.filter(item => {
                    return item.name.toLowerCase().match(searchString);
                });
            }
            items = items.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            return items;
        }
    },
    methods: {
        async getResources () {
            this.fetchingResources = true;
            const results = await this.post('getTrainingResources');
            if (results.err) {
                return;
            }
            const {resources, categories} = results;
            this.categories = categories;
            if (this.categoryId) {
                this.items = resources.filter(item => item.trainingCategoryId === this.categoryId);
            } else {
                this.items = resources.filter(item => item.trainingCategoryId);
            }
            this.getCompletionRecords();
            this.fetchingResources = false;
        },
        async getCompletionRecords () {
            const results = await this.post('getCompletionRecords', {
                username: this.currentUser.username
            });
            this.completionRecords = results;
        },
        getCategoryColor (trainingCategoryId) {
            const category = this.categories.find(category => category.id === trainingCategoryId);
            return category.color;
        },
        viewItem (item) {
            this.selectedItem = item;
            this.showDialog = true;
        },
        async videoEnded () {
            await this.markTrainingAsComplete();
            this.getCompletionRecords();
            this.$refs.player.player.stopVideo();
        },
        async markTrainingAsComplete () {
            const result = await this.post('markTrainingAsComplete', {
                trainingResourceId: this.selectedItem.id,
                username: this.currentUser.username
            });
            this.$emit('trainingCompleted');
        },
        getPdfUrl (fileName) {
            const baseUrl = this.$store.state.portalBaseUrl;
            const trainingDocsPath = 'portal/trainingdocs/';
            return `${baseUrl}${trainingDocsPath}${fileName}`;
        },
        getDocsLink (path) {
            const baseUrl = this.$store.state.portalBaseUrl;
            return `${baseUrl}docs/${path}`;
        },
        isViewed (resourceId) {
            return Boolean(this.completionRecords.find(record => record.trainingResourceId === resourceId));
        },
        goToPreviousTraining () {
            let index = this.filteredItems.indexOf(this.selectedItem);
            index -= 1;
            if (index < 0) {
                return;
            }
            this.selectedItem = this.filteredItems[index];
        },
        goToNextTraining () {
            let index = this.filteredItems.indexOf(this.selectedItem);
            index += 1;
            if (index > this.filteredItems.length - 1) {
                return;
            }
            this.selectedItem = this.filteredItems[index];
        }
    },
    watch: {
        categoryId: {
            immediate: true,
            handler () {
                this.getResources();
            }
        }
    },
    mounted () {
    }
};

</script>
<style scoped lang='scss'>
.trainingItems {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.trainingItem {
    :hover {
        cursor: pointer;
    }
    box-shadow: 2px 2px 5px lightgray;
    margin-right: 12px;
    margin-bottom: 12px;
    background-color: rgb(235, 235, 235);
    border-radius: 12px;
    overflow: hidden;
    justify-content: space-between;
    height: 150px;
    display: flex;
    flex-direction: row;
    .content {
        padding: 12px;
        width: 225px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .image {
        width: 125px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
</style>
