<router>
{
    name: 'Training',
}
</router>
<template lang="pug">
v-container
    v-row
        v-col
            v-btn(text, color='brand', @click='goToDashboard')
                v-icon arrow_left
                | Training Dashboard
    v-row
        v-col
            TrainingItems(:categoryId='categoryId')
</template>
<script>

import TrainingItems from '@/components/portal/trainingitems';

export default {
    meta: {
        // role: 'TrainingItems'
    },
    components: {
        TrainingItems
    },
    data () {
        return {
        };
    },
    methods: {
        goToDashboard () {
            this.$router.push('/customer/resources');
        }
    },
    computed: {
        categoryId () {
            return parseInt(this.$route.query.categoryId);
        }
    },
    mounted () {
        window.portalresources = this;
    }
};
</script>

<style scoped>
</style>
